<template>
  <div id="Services">
    <h1>Services</h1>
    <article>Get expert services at reasonable prices when you rely on the experienced and highly skilled team at Ace Sewing Machine Service. You can count on us for services done right and in a timely manner. With over 35 years of experience in sewing machine repairs, we have the tools and knowledge to get repairs completed so you can continue working on your projects.</article>
    <h2>QUALITY SEWING MACHINE REPAIR</h2>
    <article>Ace Sewing Machine Service provides service for all models of sewing machines. Usually a regular service will resolve 90% of machine issues, and will simply help your machine run at its best. Sewing machines become full of lint, thread, dust, fuzz, and all kinds of other matter over the course of use, and can become compacted in old grease and oil.</article>
    <div id="compWrapper">
      <div id="leftSide">
        <img src="../../public/img/icons/offer.jpg" alt="Left Side" id="left" />
      </div>
      <div id="includeWrapper">
        <h3>What’s Included?</h3>
        <img src="../../public/img/icons/whats-included.jpg" alt="whats included" id="included" />
      </div>
      <div id="rightSide">
        <img src="../../public/img/icons/side.jpg" alt="Rigth Side" id="right" />
      </div>
    </div>
    <div id="boxWrapper">
      <h4>What To Bring</h4>
      <ul>
        <li>Machine</li>
        <li>Power Cord</li>
        <li>Bobbin</li>
        <li>Foot Pedal</li>
        <li>Bobbin Case (if applicable)</li>
        <li>Zig Zag Foot (if applicable)</li>
      </ul>
      <h5>You can bring your machine in or have us come to you!</h5>
    </div>
  </div>
</template>


<style>
article {
  position: relative;
  margin: 0 auto;
  width: 60%;
  font-size: 25px;
}

h1 {
  color: rgb(195, 46, 1);
  text-shadow: 1px 1px rgba(195, 46, 1, 0.1);
  font-size: 70px;
}

h2 {
  font-size: 45px;
}

h3 {
  font-size: 40px;
}

h4 {
  color: white;
  font-size: 25px;
}
h5 {
  color: white;
  font-size: 23px;
}

#compWrapper {
  position: relative;
  display: flex;
  justify-content: space-evenly;
  width: 95%;
  margin: 0 auto;
}
#includeWrapper {
  position: relative;
}

#leftSide {
  position: absolute;
  bottom: 0;
  left: 0;
  display: flex;
  justify-content: flex-start;
}
#left {
  position: relative;
  width: 50%;
  height: 35%;
}

#rightSide {
  position: absolute;
  bottom: 0;
  right: 0;
  display: flex;
  justify-content: flex-end;
}
#right {
  position: relative;
  width: 40%;
  height: 20%;
}

#included {
  width: 40%;
}

#boxWrapper {
  margin-top: 200px;
  margin-bottom: 50px;
  background-color: rgb(160, 160, 159);
  border: 10px solid rgb(195, 46, 1);
}

ul,
li {
  position: relative;
  list-style: none;
  margin: 0 auto;
  width: 50%;
  color: white;
  text-align: left;
  font-size: 25px;
}

ul li:before {
  content: "✓";
  color: rgb(195, 46, 1);
}

@media screen and (max-width: 450px) {
  #compWrapper {
    display: block;
    padding-top: 50px;
    padding-bottom: 50px;
  }

  article {
      width: 90%;
  }
  #rightSide,
  #leftSide,
  #includeWrapper > img {
    position: relative;
    width: 95% !important;
    margin: 0 auto;
    display: block;
  }
  #includeWrapper {
    padding-bottom: 50px;
  }
  #boxWrapper {
      margin-top: 0;
  }
       ul,
       li {
          width: 90%;
          font-size: 20px;
      }
}

@media screen and (max-width: 1070px) {
  #leftSide {
    width: 40%;
  }
}
@media screen and (max-width: 1465px) {
  #rightSide {
    width: 30%;
  }
}
</style>
