<template>
  <div id="Home">
    <navigation></navigation>
    <img src="../../public/img/icons/landing-img.jpg" alt="landingImage"/>
    <services></services>
    <contact></contact>
    <footer-bar></footer-bar>
  </div>
</template>

<script>
import Navigation from '../components/NavigationComponent'
import Services from '../components/ServicesComponent'
import Contact from '../components/ContactComponent'
import FooterBar from '../components/FooterComponent'
export default {
  components: {
    Navigation,
    Services,
    Contact,
    FooterBar
  }
}
</script>

<style lang="css" scoped>
img {
  position: relative;
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin: 0;
}

  @media screen and (max-width: 450px) {
img {
  position: relative;
  top: 40px;
}
  }
</style>
