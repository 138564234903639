<template>
  <div id="navWrapper">
      <div id="nav">
      <p v-scroll-to="{ element: '#Home', duration: 5000 }">Home</p>
      <p v-scroll-to="{ element: '#Services', duration: 5000 }">Services</p>
      <p v-scroll-to="{ element: '#contactWrapper', duration: 5000 }">Contact</p>
      </div>
    </div>
</template>

<style scoped>
    #navWrapper {
        position: fixed;
        width: 100%;
        margin: 0 auto;
        padding: 0;
        z-index: 99999;
        display: flex;
        justify-content: flex-end;
    }
    #nav {
        position: relative;
        width: 580px;
        height: 40px;
        padding: 0;
        margin: 10px;
        background-color: rgba(4, 63, 90, 1);
        border-radius: 40px;
        display: flex;
        justify-content: space-evenly;
    }

    #nav > p {
        position: relative;
        padding: 0;
        margin: 0 auto;
        color: white;
        top: 5px;
        font-size: 1.3rem;
    }

    p:hover {
    cursor: pointer;
    opacity: 0.5;
  }

  @media screen and (max-width: 450px) {
      #nav {
          margin: 0;
      }
  }
</style>
