<template>
  <div id="contactWrapper">
    <h1>CONTACT</h1>
    <div id="contactBoxWrapper">
    <div id="contactBox">
      <h2><span>FREE</span> CONSULTATION</h2>
      <article>We offer FREE consultation for your machine. Bring in your machine, and we will inspect it to determine if and what repairs are needed and provide you with your options and estimate. You are not obligated for service, and we will tell you if the machine is worth the repair.</article>
      <article>Look no further for a trustworthy sewing machine repair shop in town. Make an appointment with Ace Sewing Machine Service for all your sewing machine needs.</article>
      <article>
        Local, family owned, and operated at 2140 So. River Rd Janesville, Wi. For your
        <b>FREE estimate</b>, call us at
        <a href="tel:16083620679">608-362-0679</a> or
        <a href="mailto:pennilynvandenberg@yahoo.com">email</a> us today!
      </article>
      </div>
    <div id="memoWrapper">
      <img src="../../public/img/icons/memo.jpg" alt="memo" id="memo" />
  </div>
    </div>
    <div id="mailButton">
      <a href="mailto:pennilynvandenberg@yahoo.com">EMAIL US</a>
    </div>
  </div>
</template>

<style scoped>
h1 {
  color: rgb(195, 46, 1);
  font-size: 70px;
}

h2 > span {
  color: rgb(195, 46, 1);
  font-size: 45px;
}
h2{
  color: white;
}
#contactBox > h2 {
    width: 100%;
    text-align: left;
}

article {
  color: white;
  font-size: 30px;
  text-align: left;
  margin-bottom: 20px;
  width:90%;
}

#contactBoxWrapper {
    display: flex;
    justify-content: space-evenly;
    width: 100%;
}

#contactBox {
    position: relative;
    width: 100%;
    padding: 40px 80px 40px 40px;
    margin: 0 0 60px 60px;
  background-color: rgb(4, 73, 90);
    border: 1px solid rgb(112, 112, 112);
}

#memoWrapper {
    position: relative;
    width: 26%;
    top: 250px;
    height: 20%;
    right: 160px;
}

#memo {
    position: relative;
    width: 100%
}

a {
  color: rgb(195, 46, 1);
  text-decoration: none;
}

a:hover {
    opacity: 0.5;
}
#mailButton {
  background-color: rgb(195, 46, 1);
  border: 10px solid rgb(17, 137, 163);
  border-radius: 8px;
  padding: 10px;
  width: 300px;
  margin: 0 auto;
}

#mailButton > a {
    color: white;
    font-size: 40px;
    font-weight: 600;
}
@media screen and (max-width: 1680px){
  #memoWrapper {
right: 100px;
  }
}

@media screen and (max-width: 450px){

  #contactBox > h2 {
    font-size: 25px;
    width:80%;
    text-align: center;
    margin: 0 auto;
    padding-top: 50px;
    padding-bottom: 50px;
}

#contactBoxWrapper {
    display: block;
}

#contactBox {
  display: block;
  margin: 0 auto;
  width: 99%;
  padding: 0;
}

      #memoWrapper {
          top: 0;
          width: 100%;
          padding-top: 60px;
          padding-bottom: 60px;
          right: 0;
          height: 20%;
      }
}
</style>
