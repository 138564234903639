<template>
  <footer>
      <div id="left">
          <dl>
            <dd><b>Ace Sewing Machine Service</b></dd>
            <dd><a href="https://goo.gl/maps/H8xhBRWA3i6BdsMf7">2140 So. River Rd.</a></dd>
            <dd>Janesville, Wi 53546</dd>
            <dd><a href="mailto:pennilynvandenberg@yahoo.com">pennilynvandenberg@yahoo.com</a></dd>
        </dl>
      </div>
      <div id="right">
          <p>ACE SEWING MACHINE SERVICE | COPYRIGHT &copy;{{ new Date().getFullYear() }}</p>
<p>Privacy Policy | Terms of Use</p>
      </div>
</footer>
</template>


<style>
footer {
    background-color: rgb(218, 215, 215);
    border-top: 4px solid rgb(160, 160, 160);
        display: flex;
    justify-content: space-evenly;
    width: 100%;
    margin-top: 100px;
}

#left {
    text-align: left;
    bottom: 0;
}
#right {
text-align: right;
}

a {
    text-decoration: none;
    color: navy;
}

a:hover {
    opacity: 0.5;
}

@media screen and (max-width: 450px){
footer {
    display: block;
}
#left > dl > dd > b {
    position: relative;
    width: 100%;
    margin: 0 auto;
}

dd {
    margin: 0 auto;
}
#left, #right {
    position: relative;
    margin: 0 auto;
    padding: 0;
    width: 100%;
        text-align: center;
}
}

</style>
